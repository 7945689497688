/* eslint-disable */
/* tslint-disable */

VisitWidget.FeedPostListItemsControl = class FeedPostListItemsControl extends (
  VisitWidget.ListItemsControl
) {
  constructor(containerSelector) {
    super(containerSelector, "feed_post");
    this.containerSelector = containerSelector;
  }

  addListItemsHtml(html) {
    const $listItemsHtml = $(html);
    for (let post of Array.from($listItemsHtml)) {
      const $post = $(post);
      const feedPostId = $post.data("id");

      if (VisitWidget.settings.adminModeOn) {
        html =
          '<a confirm="Are you sure you want to delete? ' +
          ' rel="nofollow" data-method="delete" href="/feed_posts/' +
          feedPostId +
          '">Delete</a>';

        const $deleteContainer = $post.find(".delete-container");
        $deleteContainer.addClass("btn transparent");
        $deleteContainer.html(html);
      }
    }

    return super.addListItemsHtml($listItemsHtml);
  }

  setupEvents() {
    $(document).on("click", `${this.containerSelector} .post`, (e) => {
      const $target = $(e.target);
      if (!this.isTargetAnotherLink($target)) {
        return $target.closest(".post").find("a.feed_post_link").click();
      }
    });

    if (VisitWidget.settings.isMobile) {
      $(document).on(
        "click",
        `${this.containerSelector} .feed_post_link`,
        (e) => {
          const $target = $(e.target);
          return $target.closest(".post").addClass("item_pressed");
        },
      );
    }
  }
};
