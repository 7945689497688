import { Location } from "../models/location";
import { MarkerData } from "./mapTypes";
import getBBox from "@turf/bbox";
import { setMapReadyAction } from "../../store/map/actions/setMapReadyAction";
import type { Feature, FeatureCollection, GeoJSON, Point } from "geojson";

VisitWidget.Map = class Map {
  infoBoxes: any[];
  useMarkerWithLabel: boolean;
  mapLoaded: boolean;
  refreshMarkersPendingMapLoadParameters: any[];
  mapGeoPagingEventOccurred: boolean;
  initializationSources: any[];
  polylines: any[];
  isLoaded: boolean;
  defaultGestureHandling: any;
  addClusteringToMarkers: boolean;
  planControlTemplate: any;
  mediaControlsTemplate: any;
  markerRouteNumber: number;
  mapOverlays: Array<MapLayer>;
  previousGeoBoxParams: any;

  constructor() {
    this.createInfoBoxHtml = this.createInfoBoxHtml.bind(this);
    this.centerMapOnDefaultCoordinates =
      this.centerMapOnDefaultCoordinates.bind(this);
    this.clearRoutes = this.clearRoutes.bind(this);
    this.infoBoxes = [];
    this.useMarkerWithLabel = false;
    this.mapLoaded = false;
    this.refreshMarkersPendingMapLoadParameters = [];
    this.mapGeoPagingEventOccurred = false;
    this.initializationSources = [];
    this.mapOverlays = [];
  }

  initialize(initializationSource) {
    this.initializationSources.push(initializationSource);
    if (
      this.initializationSources.indexOf("home_controller") < 0 ||
      this.initializationSources.indexOf("google_map_tags") < 0
    ) {
      return;
    }

    // Device Confirmation page does have a map so we want to exit early
    if ($("#main_map").length === 0) {
      return;
    }

    Gmaps.store = {};
    Gmaps.store.subpageMapLayers = [];
    Gmaps.store.compiledLocations = [];
    this.polylines = [];
    this.setDefaultGestureHandling();

    //# Default MapOptions
    const mapOptions = this.createMapOptions();

    Gmaps.store.handler = Gmaps.build("Google", {
      builders: {
        Marker: VisitWidget.InfoBoxBuilder,
      },
    });

    Gmaps.store.handler.buildMap(
      {
        provider: mapOptions,
        internal: {
          id: "main_map",
        },
      },
      () => {
        Gmaps.store.handler.clusterer.serviceObject.maxZoom_ =
          VisitWidget.settings.map_clustering_zoom_level;
        if (!VisitWidget.settings.isMobile) {
          this.resizeMapToScreen();
        }
        this.setupMapListeners();
        this.mapLoaded = true;
        // if refreshMarkers was called before the map was loaded
        if (this.refreshMarkersPendingMapLoadParameters.length !== 0) {
          this.refreshMarkers.apply(
            this,
            this.refreshMarkersPendingMapLoadParameters,
          );
          this.refreshMarkersPendingMapLoadParameters = [];
        }
        this.updateTextOverLayPosition();
        this.setupMapForAdaCompliance();
        if (VisitWidget.settings.mapLayerUrl) {
          this.addMapLayer(VisitWidget.settings.mapLayerUrl);
        }
        this.isLoaded = true;
        VisitWidget.EventBus.publish(new VisitWidget.Events.MapLoaded());

        function onLoadMap(timesTried: number) {
          if (timesTried > 20) {
            throw new Error("Error loading map.");
          }

          if ((window as any).globalDispatch) {
            (window as any).globalDispatch(setMapReadyAction());
            return;
          }

          setTimeout(
            function () {
              onLoadMap(timesTried + 1);
            },
            timesTried * 50 + 50,
          );
        }
        onLoadMap(0);

        return true;
      },
    );

    if (!VisitWidget.settings.isMobile) {
      $(window).resize(() => {
        this.resizeMapToScreen();
      });
      return $(window).resize();
    }
  }

  setupMapForAdaCompliance(timesCalled = 0) {
    // Unfortunately there's no event to ensure all map images are loaded
    // so we wait a few seconds instead
    if ($(".gm-bundled-control").length > 0) {
      $("#main_map img").attr("alt", "");
      $("#main_map").find("[tabindex],a,button,iframe").attr("tabindex", -1);
      return;
    }
    if (timesCalled > 10) {
      return;
    }

    return window.setTimeout(() => {
      return this.setupMapForAdaCompliance(timesCalled + 1);
    }, 250);
  }

  getMapLayers(grouping) {
    return this.mapOverlays.filter((x) => grouping === x.grouping);
  }

  centerKMLMapLayer(kmlLayer: google.maps.KmlLayer) {
    return window.setTimeout(() => {
      const viewPort = kmlLayer.getDefaultViewport();
      if (viewPort) {
        this.centerAndFitToBounds(viewPort);
      }
    }, 500);
  }

  isTextFeature(feature: Feature) {
    return (
      feature.type === "Feature" &&
      feature.geometry.type === "Point" &&
      !!feature.properties["text"]
    );
  }

  centerGeoJSONMapLayer(geoJSON: any) {
    const bbox = getBBox(geoJSON);
    const bounds = new google.maps.LatLngBounds(
      {
        lat: bbox[1],
        lng: bbox[0],
      },
      { lat: bbox[3], lng: bbox[2] },
    );
    this.centerAndFitToBounds(bounds);
  }

  async loadGeoJSONMapLayer(
    mapLayerUrl: string,
  ): Promise<{ features: google.maps.Data.Feature[]; geoJSON: any }> {
    let geoJSON: GeoJSON;
    try {
      const response = await fetch(mapLayerUrl);
      geoJSON = (await response.json()) as GeoJSON;
    } catch (error) {
      console.log("Error loading geoJSON");
      return;
    }
    const map = this.googleMap();
    const textFeatures = (geoJSON as FeatureCollection).features.filter((x) => {
      return this.isTextFeature(x);
    });

    const nonTextFeatures = (geoJSON as FeatureCollection).features.filter(
      (x) => {
        return !this.isTextFeature(x);
      },
    );

    textFeatures.forEach((x) => {
      const coordinates = (x.geometry as Point).coordinates;
      if (coordinates?.length !== 2) {
        return;
      }

      new google.maps.Marker({
        zIndex: 9999,
        position: { lat: coordinates[1], lng: coordinates[0] },
        label: {
          text: x.properties["text"],
          color: x.properties["color"] ?? "#333437",
          fontSize: !x.properties["fontSize"]
            ? "12px"
            : `${x.properties["fontSize"]}px`,
          fontWeight: x.properties["fontWeight"] ?? "bold",
          className: "textLabel",
        },
        map: map,
        icon: {
          url: "/images/blank.png",
        },
      });
    });

    const geoJSONFeatures = {
      type: "FeatureCollection",
      features: nonTextFeatures,
    };

    const features = map.data.addGeoJson(geoJSONFeatures);
    map.data.setStyle((feature) => {
      return {
        fillColor:
          feature.getProperty("fillColor") ?? feature.getProperty("fill"),
        fillOpacity:
          feature.getProperty("fillOpacity") ??
          feature.getProperty("fill-opacity"),
        strokeColor:
          feature.getProperty("strokeColor") ?? feature.getProperty("stroke"),
        strokeOpacity:
          feature.getProperty("strokeOpacity") ??
          feature.getProperty("stroke-opacity"),
        strokeWeight: feature.getProperty("stroke-width") ?? 2,
      };
    });

    return { features, geoJSON };
  }

  loadKMLMapLayer(mapLayerUrl: string): google.maps.KmlLayer {
    const layer = new google.maps.KmlLayer({
      url: mapLayerUrl,
      clickable: false,
      map: this.googleMap(),
      preserveViewport: true,
      suppressInfoWindows: true,
    });

    return layer;
  }

  async loadSubPageMapLayer(
    mapLayerUrl: string,
    grouping: MapLayerGrouping = undefined,
    shouldCenterOnLayer: boolean = false,
  ) {
    if (!mapLayerUrl) {
      this.removeSubpageMapLayers(grouping);
      return;
    }

    let mapLayerLoaded = false;
    // Map layers are kept in an array and only removed from the map, never
    // from memory. This seems to avoid an issue where the layer does not
    // always get removed when hovering from first on a list item with a map
    // layer, then to the map, and then to a list item without a layer.
    let kmlMapLayer: google.maps.KmlLayer;
    let geoJSONMapLayer: GeoJSONMapLayerData;

    const mapLayers = this.getMapLayers(grouping);
    const toRemove = [] as MapLayer[];
    const toShow = [] as MapLayer[];

    for (let mapLayer of mapLayers) {
      if (mapLayer.url === mapLayerUrl) {
        toShow.push(mapLayer);
      } else {
        toRemove.push(mapLayer);
      }
    }

    for (let mapLayer of toRemove) {
      this.removeMapLayer(mapLayer);
    }

    for (let mapLayer of toShow) {
      this.showMapLayer(mapLayer);
      if (mapLayer.type === "geojson") {
        geoJSONMapLayer = mapLayer.data as GeoJSONMapLayerData;
      } else if (mapLayer.type === "kml") {
        kmlMapLayer = mapLayer.data as google.maps.KmlLayer;
      }
      mapLayerLoaded = true;
    }

    if (!mapLayerLoaded) {
      // mapLayer has not yet been loaded, load it (once ever per page load)
      if (
        mapLayerUrl.indexOf(".geojson") > -1 ||
        mapLayerUrl.indexOf(".json") > -1
      ) {
        geoJSONMapLayer = await this.loadGeoJSONMapLayer(mapLayerUrl);
        this.mapOverlays.push({
          data: geoJSONMapLayer,
          url: mapLayerUrl,
          grouping,
          type: "geojson",
        });
      } else {
        kmlMapLayer = this.loadKMLMapLayer(mapLayerUrl);
        this.mapOverlays.push({
          data: kmlMapLayer,
          grouping,
          type: "kml",
          url: mapLayerUrl,
        });
      }
    }

    if (shouldCenterOnLayer) {
      if (kmlMapLayer) {
        this.centerKMLMapLayer(kmlMapLayer);
      } else if (geoJSONMapLayer) {
        this.centerGeoJSONMapLayer(geoJSONMapLayer.geoJSON);
      }
    }
  }

  private addMapLayer(url: string) {
    if (url.indexOf(".geojson") > -1 || url.indexOf(".json") > -1) {
      this.loadGeoJSONMapLayer(url);
    } else {
      this.loadKMLMapLayer(url);
    }
  }

  /**
   * Show a map layer that has already been loaded
   */
  private showMapLayer(mapLayer: MapLayer) {
    if (mapLayer.type === "kml") {
      (mapLayer.data as google.maps.KmlLayer).setMap(this.googleMap());
    } else if (mapLayer.type === "geojson") {
      const { features } = mapLayer.data as GeoJSONMapLayerData;

      const map = this.googleMap();
      features.forEach((x) => {
        map.data.add(x);
      });
      map.data.setStyle((feature) => {
        return {
          fillColor: feature.getProperty("fillColor"),
          fillOpacity: feature.getProperty("fillOpacity"),
          strokeColor: feature.getProperty("strokeColor"),
          strokeOpacity: feature.getProperty("strokeOpacity"),
          strokeWeight: 2,
        };
      });
    }
  }

  public removeMapLayer(mapLayer: MapLayer) {
    if (mapLayer.type === "kml") {
      (mapLayer.data as google.maps.KmlLayer).setMap(null);
    } else if (mapLayer.type === "geojson") {
      const map = this.googleMap();
      const { features } = mapLayer.data as GeoJSONMapLayerData;

      features.forEach((x) => {
        map.data.remove(x);
      });
    }
  }

  removeSubpageMapLayers(grouping) {
    return Array.from(this.getMapLayers(grouping)).map((mapLayer) =>
      this.removeMapLayer(mapLayer),
    );
  }

  updateTextOverLayPosition() {
    const mainFeedWidth = this.mainFeedWidth();
    if (mainFeedWidth === 0 || VisitWidget.settings.isMobile) {
      return $(".gm-style-pbc .gm-style-pbt").css("left", 0);
    } else {
      return $(".gm-style-pbc .gm-style-pbt").css("left", mainFeedWidth / 2);
    }
  }

  resizeMapToScreen() {
    $("#main_map").width(this.getMapWidth());
    $("#main_map").height(this.getMapHeight());
    return google.maps.event.trigger(
      Gmaps.store.handler.map.serviceObject,
      "resize",
    );
  }

  createMapOptions() {
    const mapOptions = {
      zoom: VisitWidget.settings.currentClientMapZoomLevel,
      center: new google.maps.LatLng(
        VisitWidget.settings.defaultCoordinates.latitude,
        VisitWidget.settings.defaultCoordinates.longitude,
      ),
      mapTypeControl: false,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      },
      zoomControl: !VisitWidget.settings.isMobile,
      zoomControlOptions: {
        style: (google.maps as any).ZoomControlStyle.SMALL,
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      panControl: false,
      styles: VisitWidget.mapTemplate,
      gestureHandling: this.defaultGestureHandling,
      streetViewControl: !VisitWidget.settings.isMobile,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT,
      },
    };

    return mapOptions;
  }

  setupMapListeners() {
    const thePanorama = Gmaps.store.handler.map.serviceObject.getStreetView();

    google.maps.event.addListener(thePanorama, "visible_changed", function () {
      if (thePanorama.getVisible()) {
        $("body").addClass("streetview");
        return;
      } else {
        $("body").removeClass("streetview");
        return;
      }
    });

    if (VisitWidget.settings.isKiosk) {
      // Allows touching the map to close the keyboard on the kiosk
      google.maps.event.addListener(
        Gmaps.store.handler.map.serviceObject,
        "click",
        () => {
          return $("input[type=text]").blur();
        },
      );
    }

    google.maps.event.addListener(
      Gmaps.store.handler.map.serviceObject,
      "idle",
      () => {
        if (!VisitWidget.map.queries.shouldDoGeographicPagination()) {
          return;
        }
        if (!this.mapGeoPagingEventOccurred) {
          return;
        }
        const geoBoxParams = this.getGeoBoxParameters();

        if (
          JSON.stringify(this.previousGeoBoxParams) !==
          JSON.stringify(geoBoxParams)
        ) {
          const { previousGeoBoxParams } = this;

          this.previousGeoBoxParams = geoBoxParams as any;
          VisitWidget.EventBus.publish(
            new VisitWidget.Events.MapGeoBoxChanged(
              previousGeoBoxParams,
              geoBoxParams,
            ),
          );
          this.mapGeoPagingEventOccurred = false;
        }
      },
    );

    google.maps.event.addListener(
      Gmaps.store.handler.map.serviceObject,
      "zoom_changed",
      () => {
        if (
          this.googleMap().getZoom() <=
          VisitWidget.settings.map_clustering_zoom_level
        ) {
          this.addClusteringToMarkers = true;
          // Gmaps.store.handler.clusterer.addMarkers(Gmaps.store.markers)
        } else {
          this.addClusteringToMarkers = false;
          if (Gmaps.store.markers) {
            for (let marker of Array.from(Gmaps.store.markers)) {
              Gmaps.store.handler.clusterer.removeMarker(marker);
            }
          }
        }

        if (!VisitWidget.map.queries.shouldDoGeographicPagination()) {
          return;
        }
        this.mapGeoPagingEventOccurred = true;
      },
    );

    google.maps.event.addListener(
      Gmaps.store.handler.map.serviceObject,
      "dragend",
      () => {
        if (!VisitWidget.map.queries.shouldDoGeographicPagination()) {
          return;
        }
        this.mapGeoPagingEventOccurred = true;
      },
    );

    if (!VisitWidget.settings.isMobile) {
      return $(document).on(
        {
          mouseenter() {
            return Gmaps.store.handler.map.serviceObject.setOptions({
              gestureHandling: "none",
            });
          },
          mouseleave: () => {
            return Gmaps.store.handler.map.serviceObject.setOptions({
              gestureHandling: this.defaultGestureHandling,
            });
          },
        },
        ".infoBox",
      );
    }
  }

  getMapWidth() {
    if (
      !VisitWidget.settings.isMobile &&
      $("#category_filter").is(":visible")
    ) {
      return $(window).outerWidth(); //- $("#category_filter").width()
    } else {
      return $(window).outerWidth();
    }
  }

  getMapHeight() {
    const topHorizontalLineHeight = 6;
    let windowHeight = $(window).outerHeight() - topHorizontalLineHeight;
    if (!VisitWidget.settings.hideHeader) {
      windowHeight = windowHeight;
    }
    return windowHeight;
  }

  getRadiusInMiles() {
    return this.getCenterLatLngAdjustedForOffsets(
      this.getViewingAreaXOffsetHalved(),
      0,
    );
  }
  getCenterLatLngAdjustedForOffsets(arg0: number, arg1: number): any {
    throw new Error("Method not implemented.");
  }

  createInfoBoxHtml(entity) {
    // The a href has empty JS to get around an issue where
    // the mobile app has map issues when cancelling the event by other means
    return `\
<div class="infobox_content clearfix"> 
  ${this.createThumbnailHtml(entity)}
  <div class="info">
    <p class="entity_name">
      <a href="javascript: ;" data-path="${entity.path}" 
        data-push="true" tabindex="-1">
        ${entity.name}
      </a>
    </p>
    <div class="entity_details">
      <p class="entity_address">${entity.location.street_address}</p>
      ${this.createCityHtml(entity)}
      ${this.createDateTimeHtml(entity)}
    </div>

  </div>
  ${this.createMediaControls(entity)}
  ${this.createPlanControlHtml(entity)}
</div>\
`;
  }

  createThumbnailHtml(entity) {
    const category = VisitWidget.categories.queries.getCategoryFromId(
      entity.category_ids[0],
    );
    const thumbnail_url = VisitWidget.GetEventOrPlaceImageUrl.execute(
      entity,
      category,
      "thumbnail",
      entity.type,
    );
    return `\
<img width="67" src="${thumbnail_url}" alt="${entity.name}" />\
`;
  }

  createCityHtml(entity) {
    if (
      VisitWidget.settings.shouldShowCityNameInShortAddress &&
      entity.location.city_from_address
    ) {
      return `<p>${entity.location.city_from_address}</p>`;
    } else {
      return "";
    }
  }

  createDateTimeHtml(entity) {
    if (entity.dateText) {
      return `<p class="entity_date">${entity.dateText}<br />${entity.time_range_text}</p>`;
    } else {
      return "";
    }
  }

  createPlanControlHtml(entity) {
    let planItemId;
    if (typeof this.planControlTemplate === "undefined") {
      const source = $("#map_plan_control_template").html();
      this.planControlTemplate = Handlebars.compile(source);
    }

    if (VisitWidget.store.globalEditMode.enabled) {
      planItemId = VisitWidget.global.getPlanItemIdFromGlobalEditMode(
        entity.id,
        entity.type,
      );
    } else {
      planItemId = VisitWidget.global.getPlanItemId(entity.id, entity.type);
    }

    const context = {
      entity_id: entity.id,
      plan_item_id: planItemId,
      plannable_type: entity.type,
    };
    const html = this.planControlTemplate(context);
    return html;
  }

  createMediaControls(entity) {
    if (typeof this.mediaControlsTemplate === "undefined") {
      const source = $("#media_controls_template").html();
      this.mediaControlsTemplate = Handlebars.compile(source);
    }

    const context = {
      video_media_file_url: undefined,
      audio_media_file_url: undefined,
    };
    if (entity.video_media_files.length > 0) {
      context.video_media_file_url = entity.video_media_files[0].stream_url;
    }
    if (entity.audio_media_files.length > 0) {
      context.audio_media_file_url = entity.audio_media_files[0].url;
    }
    const html = this.mediaControlsTemplate(context);
    return html;
  }

  // Used by mobile map it
  getNewLocation(type, entityId, callback) {
    const params = {
      type,
      entity_id: entityId,
    };
    return VisitWidget.Location.mapLocations(params, callback);
  }

  getGeoBoxParameters() {
    const swLatLng = this.getViewingAreaSouthWestLatLng();
    const neLatLng = this.getViewingAreaNorthEastLatLng();
    return {
      south_west_latitude: swLatLng.lat(),
      south_west_longitude: swLatLng.lng(),
      north_east_latitude: neLatLng.lat(),
      north_east_longitude: neLatLng.lng(),
    };
  }

  async getNewLocations(
    callback,
    type = VisitWidget.global.currentListType(),
  ): Promise<void> {
    if (
      type === "Challenge" ||
      type === "SharedPlan" ||
      type === "SelfGuided"
    ) {
      type = "Tour";
    }
    if (type === "FeedPost") {
      // load place pins as an aesthetic to not make the map feel empty.
      type = "Place";
    }

    const page = VisitWidget.global.currentListPage();
    const params = {
      type,
      page,
    };

    VisitWidget.global
      .currentListCategoryIds(true)
      .then((categoryIds: number[]) => {
        if (
          VisitWidget.map.queries.shouldDoGeographicPagination() &&
          !VisitWidget.settings.disableOnMapGeoBoxChangedHandler
        ) {
          const object = this.getGeoBoxParameters();
          for (let key in object) {
            const value = object[key];
            params[key] = value;
          }
        }

        if (VisitWidget.navigation.queries.isPlanMenuItemSelected()) {
          params["plan_id"] = VisitWidget.global.planId();
        } else {
          params["category_ids"] = categoryIds;
          params["exclusive_category_id"] =
            VisitWidget.store.currentMenuItem.selectedExclusiveCategoryId;
        }

        if (VisitWidget.store.globalEditMode.enabled) {
          params["edit_mode_entity_id"] =
            VisitWidget.store.globalEditMode.entityId;
          params["edit_mode_entity_type"] =
            VisitWidget.store.globalEditMode.entityType;
        }

        const dateRangeControl =
          VisitWidget.dateRangeControls[VisitWidget.store.currentMenuItem.id];

        if (type === "Event") {
          let toDate;
          if (dateRangeControl) {
            const fromDate = dateRangeControl.getFromDate();
            toDate = dateRangeControl.getToDate();
            params["from_date"] = fromDate.toISOString();
            params["to_date"] = toDate.toISOString();
          } else {
            params["from_date"] = VisitWidget.events.queries
              .getDefaultFromDate()
              .toISOString();
            params["to_date"] = moment()
              .add(10, "years")
              .toDate()
              .toISOString();
          }
        }

        (VisitWidget.Location.mapLocations as typeof Location.mapLocations)(
          params,
          callback,
        );
      });
  }

  centerMapOnDefaultCoordinates() {
    this.googleMap().setZoom(VisitWidget.settings.currentClientMapZoomLevel);
    const mapCenter = new google.maps.LatLng(
      VisitWidget.settings.defaultCoordinates.latitude,
      VisitWidget.settings.defaultCoordinates.longitude,
    );
    return this.mapRecenter(mapCenter, this.getViewingAreaXOffsetHalved(), 0);
  }

  centerMapOnMarkers(markers = Gmaps.store.markers) {
    if (markers.length === 0) {
      this.centerMapOnDefaultCoordinates();
      return;
    } else if (markers.length === 1) {
      this.centerOnMarker(markers[0].serviceObject);
      return;
    }

    const bounds = new google.maps.LatLngBounds();
    for (let marker of markers) {
      bounds.extend(marker.serviceObject.position);
    }
    this.centerAndFitToBounds(bounds);

    // If only one map marker the above code will zoom in way too much,
    // so the below code resets the zoom level the default
    if (markers.length < 2) {
      return this.googleMap().setZoom(
        VisitWidget.settings.currentClientMapZoomLevel,
      );
    }
  }

  centerAndFitToBounds(bounds: google.maps.LatLngBounds, padding = 0) {
    this.googleMap().fitBounds(bounds, padding);

    if (VisitWidget.settings.isMobile) {
      this.mapRecenter(
        bounds.getCenter(),
        this.getViewingAreaXOffsetHalved(),
        0,
      );
    } else {
      this.offsetDesktopMap(bounds, this.getViewingAreaXOffset(), padding);
      this.mapRecenter(
        bounds.getCenter(),
        this.getViewingAreaXOffsetHalved(),
        -padding / 2,
      );
    }
  }

  centerMapOnMarker(marker) {
    const position = marker.getPosition();
    Gmaps.store.handler.map.serviceObject.setCenter(position);
    return this.googleMap().setZoom(
      VisitWidget.settings.currentClientMapZoomLevel,
    );
  }

  addEntityLocations(
    entityType: string,
    entityId: number,
    callback: () => void,
  ) {
    return this.getNewLocation(entityType, entityId, (compiledLocations) => {
      this.addCompiledLocations(compiledLocations);
      return callback();
    });
  }

  addListLocations(): void {
    this.getNewLocations((compiledLocations: MarkerData[]) => {
      const shouldCenterOnNewMarkers =
        !VisitWidget.settings.geographicPaginationEnabled;

      this.addCompiledLocations(compiledLocations, shouldCenterOnNewMarkers);
    });
  }

  addCompiledLocations(
    compiledLocations: MarkerData[],
    shouldCenterOnNewMarkers = false,
  ): void {
    const newLocations = [];
    const newLocationExistingMarkers = [];

    for (let compiledLocation of compiledLocations) {
      const marker = this.getMarkerById(compiledLocation.markerId);
      if (marker === null) {
        newLocations.push(compiledLocation);
      } else {
        let newEntities;
        newLocationExistingMarkers.push(marker);
        const existingCompiledLocation = Gmaps.store.compiledLocations.find(
          (cl) => {
            return cl.markerId === compiledLocation.markerId;
          },
        );
        if (!VisitWidget.settings.isMobile) {
          newEntities = compiledLocation.entities.filter((entity) => {
            return !this.doesEntityMarkerAlreadyExist(
              compiledLocation.markerId,
              entity.id,
              entity.type,
            );
          });

          this.addEntitiesToMarkerInfoBox(marker, newEntities);
        }

        existingCompiledLocation.entities =
          existingCompiledLocation.entities.concat(newEntities);
      }
    }

    const newMarkers = Gmaps.store.handler.addMarkers(newLocations);
    if (
      shouldCenterOnNewMarkers &&
      newMarkers.length + newLocationExistingMarkers.length > 0
    ) {
      this.centerMapOnMarkers(newMarkers.concat(newLocationExistingMarkers));
    }
    if (!Gmaps.store.markers) {
      Gmaps.store.markers = [];
    }
    Gmaps.store.markers = Gmaps.store.markers.concat(newMarkers);
    Gmaps.store.compiledLocations =
      Gmaps.store.compiledLocations.concat(newLocations);
    this.setupEventOnMarkers(newMarkers, newLocations);
  }

  getMarkerById(markerId: string) {
    if (!this.areMarkersLoaded()) {
      return null;
    }

    let foundMarker = null;
    for (let marker of Gmaps.store.markers) {
      ((marker) => {
        if (marker.serviceObject.markerId === markerId) {
          return (foundMarker = marker);
        }
      })(marker);
    }

    if (!foundMarker && Gmaps.store.startPointMarker) {
      if (Gmaps.store.startPointMarker.serviceObject.markerId === markerId) {
        foundMarker = Gmaps.store.startPointMarker;
      }
    }

    return foundMarker;
  }

  setDefaultGestureHandling() {
    const qs = new QueryString();
    const gestureHandling = qs.value(
      "disable_map_cooperative_gesture_handling",
    );
    if (gestureHandling === "true") {
      return (this.defaultGestureHandling = "greedy");
    } else {
      return (this.defaultGestureHandling = "auto");
    }
  }

  getMarkerIds() {
    return Gmaps.store.markers.map((marker) => {
      return marker.serviceObject.markerId;
    });
  }

  addEntitiesToMarkerInfoBox(marker, entities): void {
    const $infoWindowContent = $(marker.infowindow.getContent());
    $infoWindowContent
      .find(".info-box-label")
      .html(VisitWidget.lib.translate("map.info_box.multiple_title"));

    entities.map((entity) => {
      const newHtml = this.createInfoBoxHtml(entity);
      return $infoWindowContent.find(".wrapper").append(newHtml);
    });
  }

  refreshMarkers(
    options: {
      preserveRoutes: boolean;
      recenter: boolean;
      resetMarkers: boolean;
    },
    callback: (entities?: any) => void,
  ): void {
    if (this.mapLoaded === false) {
      this.refreshMarkersPendingMapLoadParameters = [options, callback];
      return;
    }

    Gmaps.store.handler.resetBounds();

    this.getNewLocations((markersData: MarkerData[]) => {
      this.refreshMarkersWithLocations(options, callback, markersData);
    });
  }

  refreshMarkersWithLocations(
    options: {
      resetMarkers: boolean;
      preserveRoutes: boolean;
      recenter: boolean;
    },
    callback: (entities?: any) => void,
    markersData: MarkerData[],
  ) {
    this.hideInfoWindows();
    this.infoBoxes = [];
    if (!options.preserveRoutes) {
      this.clearRoutes();
    }

    this.markerRouteNumber = 1;

    this.assignMarkers(
      markersData,
      options.resetMarkers,
      options.preserveRoutes,
    );

    if (options.recenter) {
      this.centerMapOnMarkers();
    }

    if (callback != null) {
      return callback();
    }
  }

  addPlanMarkers(planCompiledLocations) {
    Gmaps.store.planCompiledLocations = planCompiledLocations;
    this.assignMarkers(planCompiledLocations, true);
    const numberOfStops =
      (Gmaps.store.startPointMarker ? 1 : 0) + planCompiledLocations.length;

    // This will also remove routes if only one stop
    VisitWidget.planItemListController.updateRoutes();

    if (numberOfStops === 1) {
      return this.centerMapOnMarkers();
    }
  }

  addPlanSurroundingMarkers() {
    const entities = Gmaps.store.planCompiledLocations
      .map((compiledLocation) => compiledLocation.entities)
      .flat();

    const params = {
      excluded_event_ids: entities
        .filter((entity) => entity.type === "Event")
        .map((entity) => entity.id),
      excluded_place_ids: entities
        .filter((entity) => entity.type === "Place")
        .map((entity) => entity.id),
      category_ids: VisitWidget.store.categoryIds,
      page: 1,
      type: VisitWidget.global.currentListType(),
    };

    const object = this.getGeoBoxParameters();
    for (let key in object) {
      const value = object[key];
      params[key] = value;
    }

    return VisitWidget.Location.mapLocations(
      params,
      (surroundingCompiledLocations) => {
        return this.addCompiledLocations(surroundingCompiledLocations);
      },
    );
  }

  assignMarkers(
    markersData: MarkerData[],
    resetMarkers = false,
    preserveRoutes = false,
  ) {
    const markersToKeep = [];
    if (resetMarkers) {
      this.clearMarkers();
    }
    if (Gmaps.store.markers === undefined) {
      Gmaps.store.markers = [];
    }
    for (let marker of Gmaps.store.markers) {
      let shouldRemoveMarker = true;
      for (let markerData of markersData) {
        if (marker.serviceObject.markerId === markerData.id) {
          shouldRemoveMarker = false;
          break;
        }
      }
      if (shouldRemoveMarker && !preserveRoutes) {
        marker.getServiceObject().setMap(null);
      } else {
        markersToKeep.push(marker);
      }
    }

    const newMarkers = Gmaps.store.handler.addMarkers(markersData);
    Gmaps.store.markers = markersToKeep.concat(newMarkers);

    // storing full detail for mobile location card
    const markerIds = Gmaps.store.markers.map((marker) => {
      return marker.serviceObject.markerId;
    });
    Gmaps.store.compiledLocations = markersData.filter((markerData) => {
      return markerIds.indexOf(markerData.id) > -1;
    });

    this.setupEventOnMarkers(newMarkers, markersData);
    if (
      this.addClusteringToMarkers &&
      !VisitWidget.navigation.queries.isPlanMenuItemSelected()
    ) {
      return Gmaps.store.handler.clusterer.addMarkers(Gmaps.store.markers);
    }
  }

  areMarkersLoaded() {
    return (
      typeof Gmaps.store !== "undefined" &&
      typeof Gmaps.store.markers !== "undefined"
    );
  }

  setupEventOnMarkers(markers, markerDataToAdd) {
    //# Add Click Event Listeners to Markers ##
    for (
      var i = 0, end = markers.length, asc = 0 <= end;
      asc ? i < end : i > end;
      asc ? i++ : i--
    ) {
      const markerData = markerDataToAdd.filter(
        (m) => m.id === markers[i].serviceObject.markerId,
      );
      this.addEventsToMarker(markers[i], markerData[0]);
    }
  }

  googleMap(): google.maps.Map {
    return Gmaps.store.handler.getMap();
  }

  mapRecenter(latLng: google.maps.LatLng, offsetX: number, offsetY: number) {
    const point = this.getPointToCenterOn(latLng, offsetX, offsetY);
    const viewingAreaLatLng = this.googleMap()
      .getProjection()
      .fromPointToLatLng(point);
    this.googleMap().setCenter(viewingAreaLatLng);
  }

  getPointToCenterOn(
    latLng: google.maps.LatLng,
    offsetX: number,
    offsetY: number,
  ) {
    const latLngToCenter =
      latLng instanceof google.maps.LatLng
        ? latLng
        : Gmaps.store.handler.map.serviceObject.getCenter();

    const pointToCenter = Gmaps.store.handler.map.serviceObject
      .getProjection()
      .fromLatLngToPoint(latLngToCenter);

    const offsetPoint = this.getOffsetPoint(offsetX, offsetY);

    const xDifference = pointToCenter.x - offsetPoint.x;
    const yDifference = pointToCenter.y + offsetPoint.y;

    return new google.maps.Point(xDifference, yDifference);
  }

  getOffsetPoint(offsetx, offsety) {
    const x = typeof offsetx === "number" ? offsetx : 0;
    const y = typeof offsety === "number" ? offsety : 0;

    const offsetPointX = this.convertPixelLengthToPointLength(x);
    const offsetPointY = this.convertPixelLengthToPointLength(y);

    return new google.maps.Point(offsetPointX, offsetPointY);
  }

  convertPixelLengthToPointLength(length) {
    return (
      length / Math.pow(2, Gmaps.store.handler.map.serviceObject.getZoom())
    );
  }

  getViewingAreaNorthEastLatLng() {
    return Gmaps.store.handler.map.serviceObject.getBounds().getNorthEast();
  }

  getViewingAreaSouthWestLatLng() {
    return this.fromPixelToLatLng(
      this.getViewingAreaXOffset(),
      this.getMapHeight(),
    );
  }

  // See https://stackoverflow.com/questions/34559018/
  // in-google-maps-js-api-frompointtolatlng-doesnt-seem-accurate-based-on-events
  fromPixelToLatLng(x, y) {
    const map = Gmaps.store.handler.map.serviceObject;
    const projection = map.getProjection();
    const topRight = projection.fromLatLngToPoint(
      map.getBounds().getNorthEast(),
    );
    const bottomLeft = projection.fromLatLngToPoint(
      map.getBounds().getSouthWest(),
    );
    const scale = 1 << map.getZoom();
    const point = new google.maps.Point(
      x / scale + bottomLeft.x,
      y / scale + topRight.y,
    );
    return projection.fromPointToLatLng(point);
  }

  mainFeedWidth() {
    let mainFeedWidth;
    if ($("#main_sidebar").is(":visible")) {
      mainFeedWidth = $("#main_sidebar").width() + 20;
    } else if (
      VisitWidget.settings.disableLists &&
      VisitWidget.flyoutLoader.isFlyoutOpen()
    ) {
      mainFeedWidth = $("#main_sidebar_flyout").width();
    } else {
      mainFeedWidth = 0;
    }

    return mainFeedWidth;
  }

  getViewingAreaXOffsetHalved() {
    return this.getViewingAreaXOffset() / 2;
  }

  getViewingAreaXOffset() {
    if (VisitWidget.settings.isMobile) {
      return 0;
    } else {
      let width = this.mainFeedWidth();
      if (VisitWidget.flyoutLoader.isFlyoutOpen()) {
        if (!VisitWidget.settings.disableLists) {
          width += $("#main_sidebar_flyout").width();
        }
      }
      if ($("#category_filter").is(":visible")) {
        width = width - $("#category_filter").width();
      }

      return width;
    }
  }

  addPolyLine(polyline) {
    polyline.setMap(Gmaps.store.handler.map.serviceObject);
    this.polylines.push(polyline);
  }

  clearPolyLines() {
    return Array.from(this.polylines).map((polyline) => polyline.setMap(null));
  }

  clearMarkers() {
    this.hideInfoWindows();
    this.infoBoxes = [];
    Gmaps.store.handler.removeMarkers(Gmaps.store.markers);
    this.clearStartingPointMarker();
    Gmaps.store.markers = [];
    Gmaps.store.compiledLocations = [];
    return this.clearRoutes();
  }

  clearStartingPointMarker() {
    if (Gmaps.store.startPointMarker) {
      Gmaps.store.startPointMarker.setMap(null);
      return (Gmaps.store.startPointMarker = null);
    }
  }

  clearRoutes() {
    if (typeof Gmaps.store["directionsDisplay"] !== "undefined") {
      Gmaps.store.directionsDisplay.set("directions", null);
    }
    return this.clearPolyLines();
  }

  hideRoutes() {
    if (typeof Gmaps.store["directionsDisplay"] !== "undefined") {
      return Gmaps.store.directionsDisplay.setMap(null);
    }
  }

  showRoutes() {
    if (typeof Gmaps.store.directionsDisplay !== "undefined") {
      return Gmaps.store.directionsDisplay.setMap(this.googleMap());
    }
  }

  hideMarkers(markerIds = null) {
    return Gmaps.store.markers.map((marker) =>
      ((marker) => {
        const markerServiceObject = marker.serviceObject;
        if (
          markerIds === null ||
          Array.from(markerIds).includes(markerServiceObject.markerId)
        ) {
          return markerServiceObject.setVisible(false);
        }
      })(marker),
    );
  }

  showMarkers(markerIds = null) {
    return (() => {
      const result = [];
      for (let marker of Gmaps.store.markers) {
        const markerServiceObject = marker.serviceObject;
        if (
          markerIds === null ||
          markerIds.includes(markerServiceObject.markerId)
        ) {
          result.push(marker.serviceObject.setVisible(true));
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  }

  addEventsToMarker(marker, markerData) {
    let markerSo;
    VisitWidget.global.markerSo = markerSo = marker.serviceObject;
    google.maps.event.addListener(markerSo, "click", (event) => {
      if (VisitWidget.settings.isKiosk) {
        // Fix issue with kiosk where map pin brings up keyboard
        $("input[type=text]").blur();
      }
      if (VisitWidget.settings.isMobile) {
        this.hideInfoWindows();
      }
      this.loadSubPageMapLayer(
        markerData.entities[0].map_layer_url,
        "listItem",
      );

      this.showMarkerInfoWindow(marker, !VisitWidget.settings.disableLists);
      VisitWidget.Analytics.createEventUA(
        "open",
        "map_pin_info_window",
        "map_pin",
      );
      return VisitWidget.Analytics.createEventGA4("MapMarkerPressed");
    });
    return google.maps.event.addListener(
      marker.infowindow,
      "closeclick",
      () => {
        return Gmaps.store.handler.map.serviceObject.setOptions({
          gestureHandling: this.defaultGestureHandling,
        });
      },
    );
  }

  showMarkerInfoWindowByMarkerId(
    markerId: string,
    panMap?: boolean | null,
    mapLayerUrl: string | null = null,
  ) {
    if (panMap == null) {
      panMap = true;
    }
    const marker = this.getMarkerById(markerId);
    if (marker !== null) {
      return this.showMarkerInfoWindow(marker, false, panMap, mapLayerUrl);
    }
  }

  showMarkerInfoWindow(
    marker: google.maps.Marker & { serviceObject: any; infowindow: any },
    hideFlyout = true,
    panMap = true,
    mapLayerUrl = undefined,
  ) {
    const markerSo = marker.serviceObject;

    if (hideFlyout && marker.infowindow) {
      VisitWidget.flyoutLoader.close();
    }

    if (panMap) {
      if (mapLayerUrl) {
        this.loadSubPageMapLayer(mapLayerUrl);
      }
      this.centerOnMarker(markerSo);
    } else {
      this.hideInfoWindows();
    }

    if (VisitWidget.settings.isMobile) {
      return VisitWidget.Mobile.map.onMarkerPressed(markerSo);
    } else if (marker.infowindow) {
      return marker.infowindow.open(markerSo.map, markerSo);
    }
  }

  addMarkerIfNotPresent(markerId, entityId, entityType, callback) {
    const originalMarker = this.getMarkerById(markerId);

    if (this.doesEntityMarkerAlreadyExist(markerId, entityId, entityType)) {
      if (callback) {
        return callback(originalMarker);
      }
    } else {
      return this.addEntityLocations(entityType, entityId, () => {
        const marker = this.getMarkerById(markerId);
        if (callback) {
          callback(marker);
        }
        const { infowindow } = marker;
        if (originalMarker) {
          const newYOffset =
            infowindow.pixelOffset_.height -
            VisitWidget.MapConstants.MULTI_ENTITY_INFO_WINDOW_HEIGHT_DIFFERENCE;
          const newOffset = new google.maps.Size(
            infowindow.pixelOffset_.width,
            newYOffset,
          );
          return infowindow.setOptions({
            pixelOffset: newOffset,
          });
        }
      });
    }
  }

  addCustomMarkerAsFirstMarker(gmapsParams, googleParams) {
    const gmapsMarker = Gmaps.store.handler.addMarker(
      gmapsParams,
      googleParams,
    );
    Gmaps.store.startPointMarker = gmapsMarker;
    return gmapsMarker;
  }

  centerOnMarker(markerSo, zoomLevel = null) {
    // `infoWindowOffset` adjusts so info window is roughly centered, currently for compact mode
    // only since that was the impetus, but no particular reason it couldn't be applied broadly.
    const infoWindowOffset = $("#content.compact").length > 0 ? 125 : 0;
    const centerPositionOffset =
      this.getViewingAreaXOffsetHalved() + infoWindowOffset;
    const mapServiceObject = Gmaps.store.handler.map.serviceObject;

    if (zoomLevel != null) {
      mapServiceObject.setZoom(zoomLevel);
    }

    if (
      VisitWidget.settings.isMobile &&
      VisitWidget.Mobile.map.isMapItViewActive()
    ) {
      mapServiceObject.setCenter(markerSo.getPosition());
    } else {
      this.hideInfoWindows();
      mapServiceObject.setCenter(markerSo.getPosition());
      if (VisitWidget.settings.isMobile) {
        mapServiceObject.panBy(-120, -100);
      } else {
        mapServiceObject.panBy(-centerPositionOffset, 0);
      }
    }
  }

  doesEntityMarkerAlreadyExist(markerId, entityId, entityType) {
    return Gmaps.store.compiledLocations.some((compiledLocation) => {
      return (
        compiledLocation.markerId === markerId &&
        compiledLocation.entities.some((entity) => {
          return entity.id === entityId && entity.type === entityType;
        })
      );
    });
  }

  hideInfoWindows() {
    if (typeof Gmaps.store.markers !== "undefined") {
      for (
        let i = 0, end = Gmaps.store.markers.length, asc = 0 <= end;
        asc ? i < end : i > end;
        asc ? i++ : i--
      ) {
        Gmaps.store.markers[i].infowindow.close();
      }
    }

    Gmaps.store.handler.map.serviceObject.setOptions({
      gestureHandling: this.defaultGestureHandling,
    });

    if (VisitWidget.settings.isMobile) {
      VisitWidget.Mobile.map.hideLocationCard();
    }
  }

  offsetDesktopMap(bounds, xOffset, yOffset) {
    this.offsetDesktopMapHorizontal(bounds, xOffset);
    return this.offsetDesktopMapVertical(bounds, yOffset);
  }

  offsetDesktopMapHorizontal(bounds, xOffset, timesCalled = 0) {
    timesCalled += 1;
    if (timesCalled > 10) {
      return;
    }

    const overlayWidth = xOffset;
    const rightMargin = 0;
    const map = Gmaps.store.handler.map.serviceObject;
    if (bounds !== false) {
      // Top right corner
      const topRightCorner = new google.maps.LatLng(
        map.getBounds().getNorthEast().lat(),
        map.getBounds().getNorthEast().lng(),
      );
      // Top right point
      const topRightPoint = this.fromLatLngToPoint(topRightCorner).x;
      // Get pixel position of leftmost and rightmost points
      const leftCoords = bounds.getSouthWest();
      const leftMost = this.fromLatLngToPoint(leftCoords).x;
      const rightMost = this.fromLatLngToPoint(bounds.getNorthEast()).x;
      // Calculate left and right offsets
      const leftOffset = overlayWidth - leftMost;
      const rightOffset = topRightPoint - rightMargin - rightMost;
      // Only if left offset is needed
      if (leftOffset >= 0) {
        if (leftOffset < rightOffset) {
          const mapOffset = Math.round((rightOffset - leftOffset) / 2);
          // Pan the map by the offset calculated on the x axis
          map.panBy(-mapOffset, 0);
          // Get the new left point after pan
          const newLeftPoint = this.fromLatLngToPoint(leftCoords).x;
          if (newLeftPoint <= overlayWidth) {
            // Leftmost point is still under the overlay
            // Offset map again
            this.offsetDesktopMapHorizontal(bounds, xOffset, timesCalled);
          }
        } else {
          // Cannot offset map at this zoom level otherwise both leftmost and
          // rightmost points will not fit
          // Zoom out and offset map again
          map.setZoom(map.getZoom() - 1);
          this.offsetDesktopMapHorizontal(bounds, xOffset, timesCalled);
        }
      }
    }
  }

  offsetDesktopMapVertical(bounds, yOffset, timesCalled = 0) {
    timesCalled += 1;
    if (timesCalled > 10) {
      return;
    }

    const overlayHeight = yOffset;
    const bottomMargin = 0;
    const map = Gmaps.store.handler.map.serviceObject;
    if (bounds !== false) {
      const topRightCorner = new google.maps.LatLng(
        map.getBounds().getNorthEast().lat(),
        map.getBounds().getNorthEast().lng(),
      );

      const bottomLeftCorner = new google.maps.LatLng(
        map.getBounds().getSouthWest().lat(),
        map.getBounds().getSouthWest().lng(),
      );
      const bottomLeftPoint = this.fromLatLngToPoint(bottomLeftCorner).y;

      const topCoords = bounds.getNorthEast();
      const bottomCoords = bounds.getSouthWest();
      const bottomMost = this.fromLatLngToPoint(bottomCoords).y;
      // top y minus the marker height
      const topMost = this.fromLatLngToPoint(topCoords).y - 65;

      const topOffset = overlayHeight - topMost;
      const bottomOffset = -(bottomMost + bottomMargin - bottomLeftPoint);

      if (topOffset >= 0) {
        if (topOffset < bottomOffset) {
          const mapOffset = Math.round((bottomOffset - topOffset) / 2);

          map.panBy(0, -mapOffset);
          const newTopPoint = this.fromLatLngToPoint(topCoords).y;

          if (newTopPoint <= overlayHeight) {
            this.offsetDesktopMapVertical(bounds, yOffset, timesCalled);
          }
        } else {
          map.setZoom(map.getZoom() - 1);
          this.offsetDesktopMapVertical(bounds, yOffset, timesCalled);
        }
      }
    }
  }

  fromLatLngToPoint(latLng) {
    const map = Gmaps.store.handler.map.serviceObject;
    const scale = Math.pow(2, map.getZoom());
    const nw = new google.maps.LatLng(
      map.getBounds().getNorthEast().lat(),
      map.getBounds().getSouthWest().lng(),
    );
    const worldCoordinateNW = map.getProjection().fromLatLngToPoint(nw);
    const worldCoordinate = map.getProjection().fromLatLngToPoint(latLng);
    return new google.maps.Point(
      Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
      Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale),
    );
  }
};

VisitWidget.map = new VisitWidget.Map();

type MapLayerGrouping = "listItem" | "menuItem" | undefined;

interface GeoJSONMapLayerData {
  features: google.maps.Data.Feature[];
  geoJSON: any;
}

interface MapLayer {
  grouping: MapLayerGrouping;
  type: "kml" | "geojson";
  url: string;
  data: GeoJSONMapLayerData | google.maps.KmlLayer;
}
